<template>
  <div>
    <div v-if="!showAdd">
      <page-header />
      <div class="table">
        <el-form :inline="true" :model="searchForm" class="demo-form-inline">
          <el-form-item label="领取时间">
            <el-date-picker
              v-model="searchForm.data.time"
              size="small"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 340px"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="使用状态">
            <el-select
              v-model="searchForm.data.state"
              size="small"
              style="width: 130px"
              placeholder="请选择"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option label="待使用" value="1"> </el-option>
              <el-option label="已使用" value="2"> </el-option>
              <el-option label="已过期" value="3"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="searchForm.data.phoneNumber"
              size="small"
              style="width: 130px"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="onSubmit">查询</el-button>
            <el-button type="primary" size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button size="small" @click="exportTable">导出</el-button>
        </div>
        <Table ref="table" :table-data="tableData" :total="total" :loading="loading" />
        <Pages
          :current-page="searchForm.pageNumber"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import pageHeader from './header'
import Table from './table'
import Pages from '@/components/pages'
import { getCouponList, grantCouponListExport } from '@/api/card'
import { downloadFile } from '@/utils/fileDownload'

export default {
  components: {
    pageHeader,
    Table,
    Pages,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      multipleSelection: [],
      isEnable: 1,
      showAdd: false,
      searchForm: {
        pageSize: 10,
        pageNumber: 1,
        data: {
          time: '',
          state: '',
        },
      },
      loading: false,
      row: {},
      radio: '',
      type: '',
    }
  },
  computed: {
    powerList: function () {
      return this.$store.getters.getPowerList
    },
  },
  watch: {},
  mounted() {
    this.searchForm.data.couponTemplateId = this.$route.query.couponTemplateId
    this.searchForm.data.state = this.$route.query.state
    this.getTableData()
  },
  methods: {
    getTableData() {
      this.loading = true
      const data = { ...this.searchForm.data }
      if (data.time) {
        data.beginTakeTime = moment(data.time[0]).format('YYYY-MM-DD HH:mm:ss')
        data.endTakeTime = moment(data.time[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.time

      getCouponList({ ...this.searchForm, data })
        .then((res) => {
          this.loading = false
          this.tableData = res.data.content
          this.total = Number(res.data.total)
        })
        .catch(() => {
          this.loading = false
        })
    },
    onSubmit() {
      this.searchForm.pageNumber = 1
      this.getTableData()
    },
    reset() {
      this.searchForm = {
        pageSize: 10,
        pageNumber: 1,
        data: {
          couponTemplateId: this.$route.query.couponTemplateId,
        },
      }
      this.onSubmit()
    },
    handleSizeChange(val) {
      this.searchForm.pageSize = val
      this.searchForm.pageNumber = 1
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val
      this.getTableData()
    },
    handleSelectionChange(val) {
      console.log('当前选择', val)
      this.multipleSelection = val
    },
    exportTable() {
      const data = { ...this.searchForm }
      data.data.isExport = 2
      this.loading = true
      grantCouponListExport(data)
        .then((res) => {
          this.loading = false
          downloadFile(res, '卡券发放记录')
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #999;
  height: 46px;
  background-color: #f9f9f9;
  padding-right: 20px;
  border: #e9e9e9 1px solid;
  margin-bottom: 15px;
  .text {
    padding: 0 4px;
    font-size: 14px;
  }
}
::v-deep .is-disabled {
  .el-checkbox__inner {
    cursor: pointer !important;
    background-color: #fff !important;
  }
  .is-checked .el-checkbox__inner {
    background-color: #409eff !important;
  }
}
::v-deep .el-checkbox__input.is-disabled .el-checkbox__inner::after {
  cursor: pointer !important;
}
::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: #fff;
}
</style>
