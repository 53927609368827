<template>
  <div class="header">
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <el-form-item class="item" label="模板名称：">
        {{ form.couponName }}
      </el-form-item>
      <el-form-item label="模板类型：">
        {{ form.couponType == 1 ? '立减券' : '满减券' }}
      </el-form-item>
      <el-form-item label="使用门槛金额：">
        {{ form.couponType == 1 ? '--' : '￥' + form.withAmount }}
      </el-form-item>
      <el-form-item label="优惠券金额："> ￥{{ form.couponAmount }} </el-form-item>
      <el-form-item label="使用限制："> 单次{{ form.useLimit }}张</el-form-item>
      <el-form-item label="生效时间：">
        {{ form.validStartType == 1 ? '发放后立即生效' : form.validStartTime }}
      </el-form-item>
      <el-form-item label="失效时间：">
        {{
          form.validType == 1
            ? '生效后' + form.validVal + getvalidUnit(form.validUnit)
            : form.validType == 2
            ? form.validEndTime
            : form.validType == 3
            ? '永久有效'
            : ''
        }}
      </el-form-item>
      <el-form-item label="总发量："> {{ form.quota }} </el-form-item>
      <el-form-item label="已发放："> {{ form.takeNum }} </el-form-item>
      <el-form-item label="已使用："> {{ form.useNum }} </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { detailCouponTemplate } from '@/api/card'
import { mixin } from '@/mixins'

export default {
  name: 'PageHeader',
  components: {},
  mixins: [mixin],
  data() {
    return {
      form: {},
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      detailCouponTemplate({
        data: this.$route.query.couponTemplateId,
      }).then((res) => {
        this.form = { ...res.data }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.header {
  ::v-deep .el-form {
    .el-form-item__content {
      color: #999;
    }
    .item {
      .el-form-item__label,
      .el-form-item__content {
        font-size: 18px;
        color: #606266;
      }
      font-weight: bold;
    }
  }
}
</style>
