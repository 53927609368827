export const mixin = {
    data() {
        return {

        }
    },
    methods: {
        getStateColor(key) {
            switch (key) {
                case 1:
                    return "#02A7F0";
                case 2:
                    return "#70B603";
                case 3:
                    return "#D9001B";
                default:
                    break;
            }
        },
        getvalidUnit(key) {
            switch (key) {
                case 1:
                    return "小时";
                case 2:
                    return "天";
                case 3:
                    return "月";
                default:
                    return "";
            }
        },
    }
}