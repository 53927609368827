<template>
  <el-table ref="multipleTable" v-loading="loading" :data="tableData" border style="width: 100%">
    <el-table-column prop="userId" label="用户ID" min-width="100"> </el-table-column>
    <el-table-column prop="phoneNumber" label="手机号" min-width="180"> </el-table-column>
    <el-table-column prop="takeTime" label="领取时间" min-width="180"> </el-table-column>
    <el-table-column prop="validStartTime" label="生效时间" min-width="180"> </el-table-column>
    <el-table-column prop="validEndTime" label="失效时间" width="180">
      <template slot-scope="scope">
        <span>
          {{ scope.row.unTime == '永久有效' ? scope.row.unTime : scope.row.validEndTime }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="stateName" label="使用状态" min-width="100">
      <template slot-scope="scope">
        <span :style="{ color: getStateColor(scope.row.state) }">
          {{ scope.row.state == 1 ? '待使用' : scope.row.stateName }}</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mixin } from '@/mixins'
export default {
  mixins: [mixin],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {
    handleSelectionChange(val) {
      this.selectData = val
      this.$emit('handleSelectionChange', val)
    },
  },
}
</script>

<style lang="less" scoped></style>
